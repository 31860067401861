import { assign } from "lodash";
import { useEffect } from "react";
import useVideoJS from "/hooks/useVideoJS.js";

export default ({
  video,
  className,
  startPlaying = false,
  options = {},
  onCanPlay = () => {},
}) => {
  const { Video, play, canPlay } = useVideoJS(
    assign(
      {
        poster: video?.poster,
        sources: [{ src: video?.src, type: video?.type }],
        controls: true,
        responsive: true,
        fluid: true,
        autoplay: false,
        // false: the same as having no attribute on the video element, won't autoplay
        // a boolean value of true: the same as having attribute on the video element, will use browsers autoplay
        // a string value of 'muted': will mute the video element and then manually call play() on loadstart. This is likely to work
        // a string value of 'play': will call play() on loadstart, similar to browsers autoplay
        // a string value of 'any'
      },
      options
    ),
    "video"
  );

  if (startPlaying) {
    setTimeout(() => {
      play();
    });
  }

  useEffect(() => {
    onCanPlay(canPlay);
  }, [canPlay]);

  return (
    <Video poster={video?.poster} className={className} playsInline></Video>
  );
};
