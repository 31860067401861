import { useRef, useEffect, useCallback } from "react";
import { useState } from "react";
import videojs from "video.js";
import "videojs-contrib-quality-levels";
import videojsqualityselector from "videojs-hls-quality-selector";

export default (options) => {
  const videoNode = useRef(null);
  const player = useRef(null);
  const [canPlay, setCanPlay] = useState(false);

  useEffect(() => {
    player.current = videojs(
      videoNode.current,
      options,
      function onPlayerReady() {
        var myPlayer = this,
          id = myPlayer.id();

        // this.src({
        //     src: liveURL,
        //     type: "application/x-mpegURL",
        //     withCredentials: false
        // });
        myPlayer.qualityLevels();
        myPlayer.hlsQualitySelector = videojsqualityselector;
        myPlayer.hlsQualitySelector({
          displayCurrentQuality: true,
        });

        player.current.on("canplay", function (e) {
          setCanPlay(true);
        });

        player.current.tech_.on("retryplaylist", function (e) {
          setCanPlay(false);
        });
      }
    );

    return () => {
      player.current.dispose();
    };
  }, [options]);

  const Video = useCallback(
    ({ children, className = "", style, ...props }) => {
      return (
        <div data-vjs-player key={options}>
          <video
            id="mystage-video"
            ref={videoNode}
            className={`video-js ${className}`}
            {...props}
          >
            {children}
          </video>
        </div>
      );
    },
    [options]
  );

  function play() {
    if (player.current) {
      player.current.play();
    }
  }

  return { Video, player: player.current, play, canPlay };
};
